<template>
  <div>
    <div class="main-box">
      <div class="item-box item-box-tip">
        <div class="title">
          <h3 class="h3-color">
            <span style="vertical-align: -2.5px; margin-left: 3px">
              <el-icon><InfoFilled /></el-icon
            ></span>
            The maximum permission for custom settings is followed by the permission to
            not schedule dates, and the minimum permission is to schedule templates for
            the week.
          </h3>
        </div>
      </div>

      <div class="item-box">
        <div class="title">
          <h3>Your Availability</h3>
          <el-button round class="btn" @click="availabilityEdit('availability')"
            >EDIT</el-button
          >
        </div>
        <div>Sun,Mon, Tue, Wed, Thu, Fri, Sat l Custom hours.</div>
      </div>
      <div class="item-box">
        <div class="title">
          <h3>Your Unavailability</h3>
          <el-button round class="btn" @click="availabilityEdit('Disable')"
            >EDIT</el-button
          >
        </div>
        <div>You can disable easy date time periods.</div>
      </div>
      <div class="item-box">
        <div class="title">
          <h3>Custom Reservation</h3>
          <el-button round class="btn" @click="availabilityEdit('Define')"
            >EDIT</el-button
          >
        </div>
        <div>You are free to set an appointment time on any given day.</div>
      </div>
      <div class="item-box">
        <div class="title">
          <h3>Reservation List</h3>
          <el-button round class="btn" @click="availabilityEdit('list')"
            >DETAIL</el-button
          >
        </div>
        <div>You can check the current number of reservations.</div>
      </div>
    </div>

    <el-dialog
      v-model="ReservationDialog"
      width="800"
      :before-close="handleClose"
      :close-on-click-modal="false"
      draggable
      overflow
    >
      <div class="container">
        <div class="availability">
          <h3 style="color: #000000">
            Set availability
            <span style="color: #dc3545" v-if="weekList.length > 0">
              (GMT{{availabilityOffsetTime}})
            </span>
            <span style="color: #dc3545" v-else> (GMT+{{ tZoneOffset }}) </span>
          </h3>
          <div class="title">
            Set the hours when you are available for business appointments
          </div>
        </div>
        <!-- <hr class="hr" /> -->
        <el-divider class="hr" border-style="dashed" />
        <div class="weeks">
          <div class="weekItem" v-for="(info, ind) in weekList" :key="ind">
            <el-row
              :gutter="24"
              class="row"
              v-for="(item, index) in info.sopList"
              :key="index"
            >
              <el-col :span="5"
                ><div class="weekName" v-if="index == 0">
                  <el-checkbox
                    @change="changBox(info, ind)"
                    v-model="item.enabled"
                    size="large"
                  /><span style="vertical-align: 1.5px; margin-left: 10px">
                    {{ item.name }}</span
                  >
                </div>
              </el-col>
              <el-col v-if="item.enabled" :span="5">
                <div class="col">
                  <el-time-select
                    v-model="item.timeStart"
                    :max-time="item.timeEnd"
                    class="select"
                    placeholder="Start time"
                    start="00:00"
                    step="01:00"
                    end="24:00"
                  /></div
              ></el-col>
              <el-col v-if="item.enabled" :span="2"
                ><div style="text-align: center">——</div></el-col
              >
              <el-col v-if="item.enabled" :span="5"
                ><div class="col">
                  <el-time-select
                    v-model="item.timeEnd"
                    class="select"
                    :min-time="item.timeStart"
                    placeholder="End time"
                    start="00:00"
                    step="01:00"
                    end="24:00"
                  /></div
              ></el-col>

              <el-col v-if="item.enabled" :span="7"
                ><div>
                  <el-button
                    v-if="info.sopList.length !== 1"
                    @click="deleteItem(ind, index)"
                    :disabled="info.sopList.length == 1"
                    style="margin-left: 40px; color: #000000; border: 1px solid #837d7d"
                    :icon="Delete"
                    circle
                  />
                  <el-button
                    v-if="index == weekList[ind].sopList.length - 1"
                    @click="addItem(ind)"
                    style="
                      color: #000000;
                      margin-left: 40px;
                      border: 1px solid #409eff;
                      background-color: rgba(64, 158, 255, 1);
                      float: right;
                      margin-top: 11.4px;
                    "
                    :icon="Plus"
                    circle
                  /></div
              ></el-col>

              <el-col
                style="color: #606266; font-weight: 700"
                v-if="!item.enabled && index == 0"
                :span="19"
                ><div>Unavailable</div></el-col
              >
            </el-row>
          </div>
        </div>
        <div style="margin-top: 10px">
          <span style="vertical-align: -2.5px; margin-left: 3px">
            <el-icon><InfoFilled /></el-icon
          ></span>
          Please select at least one time period to offer appointments
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            style="
              font-size: 10px;
              border: 1px solid #000000;
              color: #000000;
              width: 70px;
            "
            @click="ReservationDialog = false"
            round
            >CANCEL</el-button
          >
          <el-button
            style="background: #000000; color: #ffffff; font-size: 10px; width: 70px"
            @click="handleOk"
            round
          >
            SAVE
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      v-model="ReservationDialog1"
      width="700"
      :before-close="handleClose"
      :close-on-click-modal="false"
      draggable
      overflow
    >
      <div style="width: 450px; margin: auto; text-align: center">
        <h3 style="margin-bottom: 20px">Set date</h3>
        <SchedulerMobile></SchedulerMobile>
      </div>
    </el-dialog>

    <el-dialog
      v-model="ReservationDialog2"
      width="700"
      :before-close="handleClose"
      :close-on-click-modal="false"
      draggable
      overflow
    >
      <div class="disableDialog">
        <h3 class="title">
          Set Unavailability
          <span style="color: #dc3545" v-if="unavailableList.length > 0">
            (GMT{{ unavailabilityTitle }})
          </span>
          <span style="color: #dc3545" v-else> (GMT{{ timeOffsetCom }}) </span>
        </h3>
        <div class="dateSelct">
          <el-date-picker
            v-model="disableValue"
            type="daterange"
            start-placeholder="Start date"
            end-placeholder="End date"
            @change="changeSelectValue"
          />
        </div>

        <div class="ul">
          <el-row class="li liTitle">
            <el-col :span="4"
              ><div class="grid-content ep-bg-purple" />
              Id
            </el-col>
            <el-col :span="7"
              ><div class="grid-content ep-bg-purple-light" />
              StartDate
            </el-col>
            <el-col :span="7"
              ><div class="grid-content ep-bg-purple" />
              EndDate</el-col
            >
            <el-col :span="6"
              ><div class="grid-content ep-bg-purple-light" />
              Controls</el-col
            >
          </el-row>

          <el-row class="li" v-for="(item, index) in unavailableList" :key="index">
            <el-col :span="4"
              ><div class="grid-content ep-bg-purple" />
              {{ index + 1 }}
            </el-col>
            <el-col :span="7"
              ><div class="grid-content ep-bg-purple-light" />
              <el-tag v-if="item.id" type="success">{{ item.dateStart }}</el-tag>
              <el-tag v-else type="primary">{{ item.dateStart }}</el-tag>
            </el-col>
            <el-col :span="7"
              ><div class="grid-content ep-bg-purple" />
              <el-tag v-if="item.id" type="success">{{ item.dateEnd }}</el-tag>
              <el-tag v-else type="primary">{{ item.dateEnd }}</el-tag>
            </el-col>
            <el-col :span="6"
              ><div class="grid-content ep-bg-purple-light" />
              <el-button
                @click="deleteTag(item, index)"
                style="
                  color: #000000;
                  border: 1px solid #837d7d;
                  width: 28px;
                  height: 28px;
                "
                :icon="Delete"
                circle
            /></el-col>
          </el-row>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            style="
              font-size: 10px;
              border: 1px solid #000000;
              color: #000000;
              width: 70px;
            "
            @click="ReservationDialog2 = false"
            round
            >CANCEL</el-button
          >
          <el-button
            style="background: #000000; color: #ffffff; font-size: 10px; width: 70px"
            round
            @click="disabledSubmit"
          >
            SAVE
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      v-model="ReservationDialog3"
      width="1100"
      :before-close="handleClose"
      :close-on-click-modal="false"
      draggable
      overflow
    >
      <div style="height: 600px">
        <h3 style="margin-bottom: 20px; text-align: center">{{ title }}</h3>

        <el-tabs
          v-loading="loading"
          stretch
          style="width: 900px; margin: auto"
          v-model="activeName"
          class="demo-tabs"
          @tab-click="handleClick"
        >
          <el-tab-pane label="Future" name="first">
            <div style="height: 400px; overflow-y: auto">
              <el-table :data="reserveList" style="width: 850px; margin: auto">
                <el-table-column
                  prop="clientName"
                  label="Username"
                  width="100"
                  align="center"
                />
                <el-table-column
                  prop="clientEmail"
                  label="User mailbox"
                  width="200"
                  align="center"
                />
                <el-table-column
                  prop="date"
                  :label="'Appointment date' + '(' + 'GMT' + timeOffsetCom + ')'"
                  width="150"
                  align="center"
                />

                <el-table-column
                  prop="updateTime"
                  label="Creation time"
                  width="200"
                  align="center"
                />
                <el-table-column
                  prop=""
                  width="200"
                  label="Booking period"
                  align="center"
                >
                  <template #default="scope">
                    <el-tag type="primary"
                      >{{ scope.row.timeStart }}-{{ scope.row.timeEnd }}</el-tag
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="History" name="second">
            <div style="height: 400px; overflow-y: auto">
              <el-table :data="reserveList" style="width: 850px; margin: auto">
                <el-table-column
                  prop="clientName"
                  label="Username"
                  width="100"
                  align="center"
                />
                <el-table-column
                  prop="clientEmail"
                  label="User mailbox"
                  width="200"
                  align="center"
                />
                <el-table-column
                  prop="date"
                  :label="'Appointment date' + '(' + 'GMT' + timeOffsetCom + ')'"
                  width="150"
                  align="center"
                />

                <el-table-column
                  prop="updateTime"
                  label="Creation time"
                  width="200"
                  align="center"
                />
                <el-table-column
                  prop=""
                  width="200"
                  label="Booking period"
                  align="center"
                >
                  <template #default="scope">
                    <el-tag type="primary"
                      >{{ scope.row.timeStart }}-{{ scope.row.timeEnd }}</el-tag
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="page-list">
          <el-pagination
            :total="config.total"
            :page="paramsList.pageNum"
            :limit="paramsList.pageSize"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ElMessage, ElMessageBox } from "element-plus";
import { useRoute, useRouter } from "vue-router";
import {
  getOnlinePeriod,
  setOnlinePeriod,
  deleteonlinePeriod,
  getOnlinePeriodPage,
  setWeekTemplate,
  getWeekTemplate,
  getUnavailableList,
  setUnavailableList,
  getHistoryApptPage,
  getReserveApptPage,
} from "@/api/detailPage.js";
import {
  Check,
  Delete,
  Edit,
  Message,
  Search,
  Star,
  Upload,
  Download,
  Plus,
  InfoFilled,
  User,
  CircleCloseFilled,
  CloseBold,
} from "@element-plus/icons-vue";
import { ElButton, ElDialog } from "element-plus";
import { reactive, ref, onMounted, watch, nextTick, computed } from "vue";
import { getPrice, updataPrice } from "@/api/center.js";
import SchedulerMobile from "@/components/calendar/Scheduler_mobile.vue";
import { useStore } from "vuex";
import {
  checkTimeConflicts,
  addOneMonthAndSetToFirstDay,
  checkTimeConflicts1,
  nowDay,
  selectDay,
  checkOverlap,
  formatDateTime,
} from "@/components/timeTransform/index.js";
import moment from 'moment';

const reserveList = ref([]);

const disableValue = ref("");
const unavailableList = ref([]);
const defaultTime =
  ref < [Date, Date] > [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)];

const route = useRoute();
const router = useRouter();
const checked1 = ref(true);
const store = useStore();
const mainBox_disable = ref(false);
//时区偏移量
const tZoneOffset = computed(() => {
  let time = new Date().getTimezoneOffset();
  return 0 - time / 60;
});

const unavailabilityTitle = computed(() => {
  let val = unavailableList.value[0].timeZone
    ? unavailableList.value[0].timeZone > 0
      ? `+${unavailableList.value[0].timeZone}`
      : unavailableList.value[0].timeZone
    : tZoneOffset.value;
  return val;
});

const availabilityOffsetTime = computed(()=>{
  let val = weekList.value[0].timeZone
    ? weekList.value[0].timeZone > 0
      ? `+${weekList.value[0].timeZone}`
      : weekList.value[0].timeZone
    : tZoneOffset.value;
  return val;
})

//关于时区正负性的渲染
const timeOffsetCom = computed(() => {
  let val = tZoneOffset.value > 0 ? `+${tZoneOffset.value}` : tZoneOffset.value;
  return val;
});

const expertId = computed(() => {
  return JSON.parse(store.state.userInfo).id;
});
const title = ref("View Future");

const queryParams = ref({
  pageNum: "1",
  pageSize: "10",
  expertId: expertId.value,
  timeZone: tZoneOffset.value,
});
const activeName = ref("first");

// 1 参数
const weekQuery = ref({
  expertId: expertId.value,
  weekOrder: 0,
});

const weekList = ref([]);
const ReservationDialog = ref(false);
const ReservationDialog1 = ref(false);
const ReservationDialog2 = ref(false);
const ReservationDialog3 = ref(false);
const loading = ref(true);

const changeSelectValue = (e) => {
  if (e[1] < new Date(new Date().getTime() - 24 * 60 * 60 * 1000)) {
    ElMessage({
      type: "error",
      message: "The set date cannot be younger than the current date",
    });
    return;
  }
  const arr = {
    dateStart: selectDay(e[0]),
    dateEnd: selectDay(e[1]),
    delFlag: false,
    expertId: expertId.value,
    timeZone: tZoneOffset.value,
  };

  unavailableList.value.push(arr);
  // setTimeout(() => {
  //   disableValue.value = "";
  // }, 500);
};

const disabledSubmit = async () => {
  if (unavailableList.value.length === 0) {
    ElMessage({
      type: "error",
      message: "The period cannot be empty",
    });
    return;
  }
  let x = checkOverlap(unavailableList.value);
  if (x && unavailableList.value.length > 0) {
    console.log("通过");
    let data = _.cloneDeep(unavailableList.value);
    data = data.map((x) => {
      return {
        ...x,
        timeZone: tZoneOffset.value,
      };
    });
    console.log(data, "data");
    // return;
    let res = await setUnavailableList(data);
    if (res.code == 200) {
      getUnavailable();
      ElMessage({
        type: "success",
        message: "Save successfully.",
      });
    }
  } else {
    ElMessage({
      type: "error",
      message: "The set period is invalid",
    });
  }
};

const deleteTag = (item, index) => {
  if (item.id) {
    ElMessageBox.confirm(
      `Are you sure you want to delete <span style="color:#10bd8e;">${item.dateStart}</span> to  <span style="color:#dc3545;">${item.dateEnd}</span> ?`,
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        dangerouslyUseHTMLString: true,
      }
    )
      .then(async () => {
        let data = [
          {
            ...item,
            delFlag: true,
          },
        ];
        let res = await setUnavailableList(data);
        if (res.code == 200) {
          unavailableList.value.splice(index, 1);
          ElMessage({
            type: "success",
            message: "Delete completed",
          });
        }
      })
      .catch(() => {
        ElMessage({
          type: "info",
          message: "Delete canceled",
        });
      });
  } else {
    unavailableList.value.splice(index, 1);
  }
};

const changBox = (item, index) => {
  console.log(item, "item");
  console.log(index, "index");
  weekList.value[index].enabled = !weekList.value[index].enabled;

  const { enabled } = item.sopList[0];

  for (let i = 1; i < item.sopList.length; i++) {
    item.sopList[i].enabled = enabled;
  }
  console.log(weekList.value, "weekList.value");
};

const getWeekList = async () => {
  let res = await getWeekTemplate(weekQuery.value);
  if (res && res.code == "200") {
    //         timeStart: info.timeStart.slice(0, 5),
    //         timeEnd: info.timeEnd.slice(0, 5),
    weekList.value = res.data.map((x) => {
      if (x.sopList.length === 0) {
        // 如果 sopList 为空，则创建一个包含 tag 的对象
        return {
          ...x,
          sopList: [
            {
              name: x.tag,
              enabled: x.enabled,
              timeStart: "",
              timeEnd: "",
              expertId: expertId.value,
              timeZone: tZoneOffset.value,
            },
          ],
        };
      } else {
        // 否则，将每个 sopList 元素的 name 设置为 tag，并返回更新后的对象
        return {
          ...x,
          sopList: x.sopList.map((item) => {
            return {
              ...item,
              name: x.tag,
              enabled: x.enabled,
              timeStart: item.timeStart.slice(0, 5),
              timeEnd: item.timeEnd.slice(0, 5),
            };
          }),
        };
      }
    });
  }
  console.log(weekList.value, "weekList.value1");
};

const getUnavailable = async () => {
  let data = {
    expertId: expertId.value,
    validDate: true,
  };
  let res = await getUnavailableList(data);
  if (res.code == 200) {
    unavailableList.value = res.data.map((item) => {
      return {
        ...item,
        dateStart: item.dateStart.slice(0, 10),
        dateEnd: item.dateEnd.slice(0, 10),
      };
    });
  }
  console.log(res, "res");
};

const availabilityEdit = async (item) => {
  if (window.innerWidth > 768) {
    if (item == "availability") {
      ReservationDialog.value = true;
      getWeekList();
    } else if (item == "Define") {
      ReservationDialog1.value = true;
    } else if (item == "Disable") {
      ReservationDialog2.value = true;
      getUnavailable();
    } else if (item == "list") {
      ReservationDialog3.value = true;
      // getAppointmentList();
      title.value = "View Future";
      getReserveApptPageList();
    }
  } else {
    router.push({
      path: "/mobileScheduler",
      query: {
        type: `${item}`,
      },
    });
  }
};

const addItem = (ind) => {
  console.log(ind, "ind");
  // return;
  weekList.value[ind].sopList.push({
    expertId: expertId.value,
    name: weekList.value[ind].sopList[0].name,
    enabled: weekList.value[ind].sopList[0].enabled,
    timeStart: "",
    timeEnd: "",
    timeZone: tZoneOffset.value,
    published: false,
    published_ui: false,
  });
  console.log(weekList.value, "weekList.value");
};

const deleteItem = (ind, index) => {
  const parent = weekList.value[ind];

  parent.sopList.splice(index, 1);

  console.log(weekList.value, "weekList.value");
};

//pc端周模板提交修改
const handleOk = async () => {
  let Switch = weekList.value.every((x) => !x.enabled);
  if (weekList.value.length > 0 && !Switch) {
    let arr = [];
    weekList.value.forEach((item) => {
      if (!item.enabled) {
      } else {
        item.sopList.forEach((info) => {
          arr.push(info);
        });
      }
    });
    console.log(arr, "arr");

    let isSuccess = checkTimeConflicts1(arr);
    if (isSuccess) {
      console.log("成功", weekList.value);

      let query = _.cloneDeep(weekList.value);
      console.log(query, "query");
      let arr = [];
      query.forEach((info) => {
        if (!info.enabled) {
          const filteredSopList = info.sopList.filter((x) => x.timeStart && x.timeEnd);
          info.sopList = filteredSopList;
        }
        arr.push(info);
      });

      arr = arr.map((item, index) => ({
        ...item,
        expertId: expertId.value,
        timeZone: tZoneOffset.value,
        sopList: item.sopList.map((i) => ({
          ...i,
          parentId: arr[index].id,
          timeStart: i.timeStart + ":00",
          timeEnd: i.timeEnd === "00:00" ? "24:00:00" : i.timeEnd + ":00",
          expertId: expertId.value,
          timeZone: tZoneOffset.value,
        })),
      }));
      console.log(arr, "提交的数组");
      // return;
      let res = await setWeekTemplate(arr);
      console.log(res, "res");
      if (res.code == "200") {
        ElMessage({
          showClose: true,
          message: "Save successfully.",
          type: "success",
        });
        getWeekList();
      }
    } else {
      console.log("失败");
      ElMessage({
        showClose: true,
        message: "The time period you set does not meet the specification!",
        type: "error",
      });
    }
  } else {
    ElMessage({
      showClose: true,
      message: "Please set a date for at least one period!",
      type: "warning",
    });
  }
};

const handleEdit = (item, index) => {
  console.log(item, index);
};

const handleDelete = (item, index) => {
  reserveList.value = reserveList.value.filter((x) => x.id !== item.id);
  console.log(item, index);
};
const config = ref({
  total: 0,
});

const paramsList = ref({
  expertId: expertId.value,
  timeZone: tZoneOffset.value,
  pageNum: 1,
  pageSize: 10,
});

const handleSizeChange = (val) => {
  console.log(val, "页");
  paramsList.value.pageNum = val;
  getAppointmentList();
};
const handleCurrentChange = (val) => {
  console.log(val, "分");
  paramsList.value.pageNum = val;
  getAppointmentList();
};

const handleClick = (tab, e) => {
  paramsList.value.pageNum = "1";
  reserveList.value = [];
  console.log(tab.props.label);
  if (tab.props.label == "History") {
    title.value = "View History";
    getAppointmentList();
  } else {
    title.value = "View Future";
    getReserveApptPageList();
  }
  console.log(e);
};

//获取预约列表
const getAppointmentList = async () => {
  loading.value = true;
  const { code, rows, total } = await getHistoryApptPage(paramsList.value);
  if (code == 200 && rows.length > 0) {
    loading.value = false;
    config.value.total = total;
    reserveList.value = rows.map((item) => {
      return {
        ...item,
        date: item.date.slice(0, 10),
        timeStart: item.timeStart.slice(0, 5),
        timeEnd: item.timeEnd.slice(0, 5),
        updateTime: moment(item.createTime).add(8, 'hour').format('YYYY-MM-DD HH:mm')
      };
    });
    console.log(rows, "rows");
  } else {
    loading.value = false;
  }
};

//获取未来预约列表
const getReserveApptPageList = async () => {
  loading.value = true;
  const { code, rows, total } = await getReserveApptPage(paramsList.value);
  if (code == 200 && rows.length > 0) {
    loading.value = false;
    config.value.total = total;
    reserveList.value = rows.map((item) => {
      return {
        ...item,
        date: item.date.slice(0, 10),
        timeStart: item.timeStart.slice(0, 5),
        timeEnd: item.timeEnd.slice(0, 5),
        updateTime: moment(item.createTime).add(8, 'hour').format('YYYY-MM-DD HH:mm')
      };
    });
    console.log(rows, "rows");
  } else {
    loading.value = false;
  }
};

onMounted(() => {});
</script>

<style lang="scss" scoped>
/* 移动端样式 */
@media screen and (max-width: 768px) {
  .main-box {
    height: calc(100vh - 50px) !important;
    overflow-y: auto !important;
  }
}

:deep(.el-range-editor) {
  width: 400px;
}

.disableDialog {
  height: 500px;
  // background: #a2afb9;
  text-align: center;
  border-radius: 12px;
  padding: 20px;
  .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .dateSelct {
    margin-bottom: 20px;
  }
  .ul {
    height: 420px;
    width: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #eeeeee;
    margin: auto;

    .liTitle {
      background-color: #eeeeee;
      font-weight: 600;
    }

    .li {
      display: flex;
      justify-content: space-between;
      width: 400px;
      margin-bottom: 20px;
      line-height: 25px;
      padding: 5px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.3s ease;

      .id {
        width: 20px;
        text-align: right;
      }

      .li-icon {
        vertical-align: -2.5px;
        cursor: pointer;
      }
    }
  }
}

.main-box {
  background: #efefef;
  height: 1023px;
  padding: 10px 0;
  boxsizeing: border-box;
  overflow-y: auto;
  .item-box {
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    background: #ffffff;
    border-radius: 12px;
    width: 95%;
    margin: 10px auto;
    padding: 10px 20px 30px 20px;

    .title {
      display: flex;
      justify-content: space-between;
      padding: 10px 0px;

      .h3-color {
        color: #dc3545;
      }

      .btn {
        width: 70px;
        border: 1px solid #efefef;
        font-size: 12px;
        color: #000000;
      }
    }
  }
}
.item-box-tip {
  padding: 10px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #9f9f9f; /* 设置滚动条拖动部分的颜色 */
}
::-webkit-scrollbar {
  width: 2px; /* 设置滚动条宽度 */
}
.hr {
  margin-top: 20px;
  margin-bottom: 20px;
}
.container {
  background: #ffffff;
  padding: 3%;

  .availability {
    text-align: center;
    margin: auto;
  }

  .title {
    margin-top: 10px;
  }
  .weeks {
    overflow-y: auto;
    overflow-x: hidden;
    height: 520px;
    .weekItem {
      // box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
      // height: 100px;
      border-bottom: 1px solid #9f9f9f;
      line-height: 50px;
      padding: 10px 10px;
      .weekName {
        font-weight: bold;
        color: #000000;
      }
    }
  }
  .btn_footer {
    margin-top: 20px;
  }
}
.page-list {
  margin-top: 20px;
  display: flex;
  justify-content: right;
  margin-right: 100px;
}
</style>
